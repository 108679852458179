<template>
  <div class="text-muted d-inline">
    <template v-for="key in value" :key="key">
      <template v-if="key === 'meta'"><span v-html="metaCharacter"/></template>
      <template v-else-if="key === 'shift'">&#8679;</template>
      <template v-else-if="key === 'ctrl'">&#8963;</template>
      <template v-else-if="key === 'alt'">&#x2325;</template>
      <template v-else>{{key.toUpperCase()}}</template>
    </template>
  </div>
</template>

<script>
export default {
  name: 'shortkeyView',
  props: ['value'],
  data () {
    return {
      keys: [],
      metaCharacter: '&#8984;'
    }
  },
  created () {
    this.processKeys()
  },
  methods: {
    processKeys () {
      if (navigator.appVersion.indexOf('Win') !== -1) {
        this.metaCharacter = '<span style="font-size: 1rem">&#8862;</span>'
      }
    }
  }
}
</script>

<style scoped>

</style>
